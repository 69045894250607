import {
  loaderReducers,
  profileUrlReducers,
} from "../common/store/commonReducer";
import { getUserMedicalParametersReducers } from "../modules/ABCDsModule/store/abcdsReducer";
import {
  getZoomIDReducerReducers,
  getZoomListReducers,
} from "../modules/AskTheDocModule/store/askthedocReducer";
import { getSubscriptionsAllPlansReducers } from "../modules/BillingModule/store/billingReducer";
import {
  getClinicsIDReducers,
  getClinicsReducers,
  getTestosteronologistReducers,
} from "../modules/FindProviderModule/store/findproviderReducer";
import { getHistoryPhysicalReducers } from "../modules/HistoryPhysicalModule/store/historyPhysicalReducer";
import {
  getFileReducers,
  getLabReportReducers,
} from "../modules/MyLabModule/store/mylabReducers";
import { getUserReducers } from "../modules/ProfileModule/store/profileReducer";
import { subscribeReducers } from "../modules/SignUpModule/store/signupReducer";
import { getIAPSubscriptionReducers } from "../modules/SigninModule/store/signinReducer";
import {
  getTodoItemReducers,
  getTodoListReducers,
} from "../modules/TodoModule/store/todoReducer";
import { getVimeoReducers } from "../modules/VideoModule/store/videoReducer";

export const reducers = {
  loaderReducers,
  getUserReducers,
  getFileReducers,
  getLabReportReducers,
  getVimeoReducers,
  getUserMedicalParametersReducers,
  getZoomIDReducerReducers,
  getZoomListReducers,
  getTodoListReducers,
  getTodoItemReducers,
  profileUrlReducers,
  subscribeReducers,
  getHistoryPhysicalReducers,
  getClinicsReducers,
  getClinicsIDReducers,
  getTestosteronologistReducers,
  getIAPSubscriptionReducers,
  getSubscriptionsAllPlansReducers,
};
