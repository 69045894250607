import { Box, Typography } from "@mui/material";
import {
  GoogleMap as GoogleMapReact,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useState, useRef } from "react";
import { BLACK } from "../../theme/colors";
import { FONTS } from "../../utils/fontConstant";
import Loader from "../Loader/Loader";
import { LocationsEntity } from "../../modules/FindProviderModule/store/findprovider.types";

type Props = {
  mapContainerStyle?: React.CSSProperties;
  mapContainerClassName?: string;
  markers: LocationsEntity[];
};

const GoogleMap = ({
  mapContainerStyle,
  mapContainerClassName,
  markers,
}: Props) => {
  const [activeMarker, setActiveMarker] = useState<string | null>(null);
  const mapRef = useRef<any>(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // @ts-ignore
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const handleActiveMarker = (markerId: string) => {
    if (markerId === activeMarker) {
      return;
    }
    setActiveMarker(markerId);
  };

  const handleOnLoad = (map: any) => {
    mapRef.current = map;
    const bounds = new window.google.maps.LatLngBounds();

    // Extend bounds based on markers' positions
    markers.forEach(({ locationCoordinates }) => {
      const position = {
        lat: locationCoordinates.coordinates[0],
        lng: locationCoordinates.coordinates[1],
      };
      bounds.extend(position);
    });

    // Set a minimum zoom level (e.g., 10) to prevent too much zooming out
    if (markers.length === 1) {
      map.setZoom(15); // Set a custom zoom level for a single marker
      map.setCenter(bounds.getCenter()); // Center on the single marker
    } else {
      map.fitBounds(bounds);
    }
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <GoogleMapReact
      zoom={15}
      mapContainerClassName={mapContainerClassName}
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={mapContainerStyle}
    >
      {markers.map(({ _id, name, locationCoordinates }) => {
        const position = {
          lat: locationCoordinates.coordinates[0],
          lng: locationCoordinates.coordinates[1],
        };

        return (
          <MarkerF
            key={_id}
            position={position}
            onClick={() => handleActiveMarker(_id)}
          >
            {activeMarker === _id ? (
              <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                <Box>
                  <Typography
                    fontSize={16}
                    fontFamily={FONTS.SOURCESANSPRO_BOLD}
                    color={BLACK}
                  >
                    {name}
                  </Typography>
                </Box>
              </InfoWindowF>
            ) : null}
          </MarkerF>
        );
      })}
    </GoogleMapReact>
  );
};

export default GoogleMap;
