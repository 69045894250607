import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { historyPhysicalApi } from "../../../routes/apiRoutes";
import {
  GET_HISTORY_PHYSICAL,
  POST_HISTORY_PHYSICAL,
} from "../../../redux/actions";

export const getHistoryPhysicalMiddleWare = createAsyncThunk(
  GET_HISTORY_PHYSICAL,
  async (_a) => {
    try {
      const { data } = await axios.get(historyPhysicalApi);
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const updateHistoryPhysicalMiddleWare = createAsyncThunk(
  POST_HISTORY_PHYSICAL,
  async ({
    currentPage,
    questionAndAnswers,
    pageDetails,
  }: {
    currentPage?: string;
    questionAndAnswers?: any;
    pageDetails?: any;
  }) => {
    try {
      const { data } = await axios.post(historyPhysicalApi, {
        currentPage,
        pageDetails,
        questionAndAnswers,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
