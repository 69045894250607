import { createSlice } from "@reduxjs/toolkit";
import { GetSubscriptionsAllPlansReducerState } from "./billing.types";
import { getSubscriptionsAllPlansMiddleWare } from "./billingMiddleware";

const getSubscriptionsAllPlansState: GetSubscriptionsAllPlansReducerState = {
  isLoading: false,
  error: "",
  data: {
    paid: {
      name: "",
      UI_MODE: "",
      MODE: "",
      NAME: "",
      PRICE_ID: "",
      PRODUCT_ID: "",
      PRICE: 0,
      CURRENCY: "",
      RECURRING: { INTERVAL: "", INTERVAL_COUNT: 0 },
    },
    testosteronology_affiliate_licensing_monthly: {
      name: "",
      UI_MODE: "",
      MODE: "",
      NAME: "",
      PRICE_ID: "",
      PRODUCT_ID: "",
      PRICE: 0,
      CURRENCY: "",
      RECURRING: { INTERVAL: "", INTERVAL_COUNT: 0 },
    },
    testosteronology_affiliate_licensing_annual: {
      name: "",
      UI_MODE: "",
      MODE: "",
      NAME: "",
      PRICE_ID: "",
      PRODUCT_ID: "",
      PRICE: 0,
      CURRENCY: "",
      RECURRING: { INTERVAL: "", INTERVAL_COUNT: 0 },
    },
    country: "",
  },
};

const getSubscriptionsAllPlansReducer = createSlice({
  name: "get-subscription-all-plans",
  initialState: getSubscriptionsAllPlansState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionsAllPlansMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getSubscriptionsAllPlansMiddleWare.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      getSubscriptionsAllPlansMiddleWare.rejected,
      (state, action) => {
        state.isLoading = false;
        if (typeof action.payload === "string") {
          state.error = action.payload;
        }
      }
    );
  },
});

export const getSubscriptionsAllPlansReducers =
  getSubscriptionsAllPlansReducer.reducer;
