import { connect } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ParticleEmitter } from "./packages";
import { memo, useEffect, useRef } from "react";
import { getHistoryPhysicalMiddleWare } from "./modules/HistoryPhysicalModule/store/historyPhysicalMiddleware";
import { isEmpty } from "./utils/validators";
import { AUTH_TOKEN } from "./utils/localStoreConst";
import AppProvider from "./AppProvider";
import { RootState, useAppDispatch } from "./redux/store";
import Loader from "./packages/Loader/Loader";
import { ANALYTICS_EVENT } from "./utils/analyticsConstants";
import { setDefaultEventParameters } from "./utils/analyticsHelper";
import { getSubscriptionsAllPlansMiddleWare } from "./modules/BillingModule/store/billingMiddleware";
import { setLoader } from "./common/store/commonReducer";

const mapStateToProps = (state: RootState) => {
  const { loaderReducers, getUserReducers } = state;
  return {
    isLoader: loaderReducers.isLoader,
    userId: getUserReducers?.userDetails?._id,
    role: getUserReducers?.userDetails?.role,
  };
};

type Props = {
  isLoader: boolean;
  userId: string;
  role: string;
};

const AppLayout: React.FC<Props> = memo(({ isLoader, userId, role }: Props) => {
  const dispatch = useAppDispatch();
  const isDispatched = useRef(false);
  const isDispatched1 = useRef(false);

  useEffect(() => {
    setDefaultEventParameters({
      personId: isEmpty(userId) ? ANALYTICS_EVENT.NOT_SIGNIN : userId,
      platform: "web",
      role: isEmpty(role) ? ANALYTICS_EVENT.NOT_SIGNIN : role,
    });
  }, [userId, role]);

  useEffect(() => {
    if (!isDispatched.current && !isEmpty(localStorage.getItem(AUTH_TOKEN))) {
      dispatch(getHistoryPhysicalMiddleWare()).finally(() => {
        isDispatched.current = true;
      });
    }
  }, []);

  useEffect(() => {
    if (!isDispatched1.current) {
      dispatch(setLoader(true));
      dispatch(getSubscriptionsAllPlansMiddleWare()).finally(() => {
        isDispatched.current = true;
        dispatch(setLoader(false));
      });
    }
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ParticleEmitter />
        {isLoader && <Loader />}
        <AppProvider />
      </LocalizationProvider>
    </>
  );
});

export default connect(mapStateToProps)(AppLayout);
