// src/facebookPixelHelper.ts
import ReactPixel from "react-facebook-pixel";

// Define a type for event data to ensure type safety
type EventData = Record<string, any>;

/**
 * Initialize Facebook Pixel with your Pixel ID.
 * @param pixelId - The Facebook Pixel ID.
 */
export const initFacebookPixel = (pixelId: any): void => {
  ReactPixel.init(pixelId);
  ReactPixel.pageView(); // Track initial page view
};

/**
 * Track a standard Facebook Pixel event.
 * @param eventName - The name of the event (e.g., 'Purchase', 'Lead').
 * @param data - Optional data to send with the event.
 */
export const trackEvent = (eventName: string, data: EventData = {}): void => {
  ReactPixel.track(eventName, data);
};

/**
 * Track a custom Facebook Pixel event.
 * @param eventName - The name of the custom event.
 * @param data - Optional data to send with the event.
 */
export const trackCustomEvent = (
  eventName: string,
  data: EventData = {}
): void => {
  ReactPixel.trackCustom(eventName, data);
};
