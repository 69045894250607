import {
  IconButton,
  SxProps,
  Theme,
  Tooltip as TooltipMUI,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { FONTS } from "../../utils/fontConstant";
import { SvgBoxInfo } from "../../icons";

type Props = {
  title: ReactNode;
  iconButtonSx?: SxProps<Theme>;
};
const Tooltip = ({ title, iconButtonSx }: Props) => {
  const [isTooltip, setTooltip] = useState(false);
  return (
    <TooltipMUI
      leaveTouchDelay={10000}
      open={isTooltip}
      onClose={() => setTooltip(false)}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "#090615",
            border: "0.5px solid rgba(107, 106, 115, 0.40)",
            fontFamily: FONTS.SOURCESANSPRO_REGULAR,
            p: 2,
            "& .MuiTooltip-arrow": {
              color: "common.black",
            },
          },
        },
      }}
      sx={{ color: "white", fontFamily: FONTS.SOURCESANSPRO_REGULAR }}
      title={title}
    >
      <IconButton sx={iconButtonSx} onClick={() => setTooltip(!isTooltip)}>
        <SvgBoxInfo />
      </IconButton>
    </TooltipMUI>
  );
};

export default Tooltip;
