import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FORGOT_PASSWORD_OTP,
  FORGOT_PASSWORD_OTP_VALIDATION,
  GET_IAP_SUBSCRIPTION,
  LOGIN,
  OTP_VALIDATION,
  RESET_PASSWORD,
} from "../../../redux/actions";
import {
  forgotPasswordApi,
  forgotPasswordValidationApi,
  getIAPSubscriptionApi,
  loginApi,
  otpValidationApi,
  resetPasswordApi,
} from "../../../routes/apiRoutes";

export const loginMiddleWare = createAsyncThunk(
  LOGIN,
  async ({ email, password }: { email: any; password: any }) => {
    try {
      const { data } = await axios.post(loginApi, { email, password });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const otpValidationMiddleWare = createAsyncThunk(
  OTP_VALIDATION,
  async ({ userId, otp }: { userId: any; otp: string }) => {
    try {
      const { data } = await axios.post(otpValidationApi, { userId, otp });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const forgotPasswordMiddleWare = createAsyncThunk(
  FORGOT_PASSWORD_OTP,
  async ({ email }: { email: any }) => {
    try {
      const { data } = await axios.post(forgotPasswordApi, { email });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const forgotPasswordValidationMiddleWare = createAsyncThunk(
  FORGOT_PASSWORD_OTP_VALIDATION,
  async ({ userId, otp }: { userId: any; otp: string }) => {
    try {
      const { data } = await axios.post(forgotPasswordValidationApi, {
        userId,
        otp,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const resetPasswordMiddleWare = createAsyncThunk(
  RESET_PASSWORD,
  async ({ token, password }: { token: string; password: string }) => {
    try {
      const { data } = await axios.post(
        resetPasswordApi,
        {
          password,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getIAPSubscriptionMiddleWare = createAsyncThunk(
  GET_IAP_SUBSCRIPTION,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(getIAPSubscriptionApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  }
);
