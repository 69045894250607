import { Box, Button, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { FONTS } from "../utils/fontConstant";
import { PRIMARY } from "../theme/colors";
import styles from "./hpcontainer.module.scss";
import { useLanguage } from "../lang/language";
import { routes } from "../routes/routesPath";
import { ANALYTICS_EVENT } from "../utils/analyticsConstants";
import { analyticsEvent } from "../utils/analyticsHelper";

const { UPGRADE_TO_PRO_PLAN } = useLanguage;

type Props = {
  children: ReactNode;
  handleSubmit?: () => void;
  buttonTitle?: string;
  title?: string;
  disabledSubmit?: boolean;
};
const HPContainer = ({
  children,
  handleSubmit,
  buttonTitle = "Continue",
  title,
  disabledSubmit,
}: Props) => {
  const navigate = useNavigate();

  const handleUpgrade = () => {
    analyticsEvent({
      eventName: ANALYTICS_EVENT.UPGRADE_TO_PRO_PLAN_BTN,
    });
    navigate(routes.BILLING);
  };
  return (
    <Box className={styles.overAll}>
      <Typography
        fontSize={16}
        color={PRIMARY}
        fontFamily={FONTS.MONTSERRAT_BOLD}
      >
        {title}
      </Typography>
      <br />
      {children}
      <br />
      <br />
      <Button
        onClick={handleUpgrade}
        sx={{ textTransform: "capitalize" }}
        variant="contained"
        color="secondary"
      >
        {UPGRADE_TO_PRO_PLAN}
      </Button>
      <br />
      <br />
      <Box className={styles.btnBox}>
        {typeof handleSubmit === "function" && (
          <Button
            disabled={disabledSubmit}
            onClick={handleSubmit}
            size="large"
            variant="contained"
            sx={{ textTransform: "capitalize" }}
          >
            {buttonTitle}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default HPContainer;
