import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  couponValidateApi,
  getSubscriptionsAllPlans,
} from "../../../routes/apiRoutes";
import {
  COUPON_VALIDATE,
  GET_SUBSCRIPTIONS_ALL_PLANS,
} from "../../../redux/actions";

export const couponValidateMiddleWare = createAsyncThunk(
  COUPON_VALIDATE,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.get(couponValidateApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getSubscriptionsAllPlansMiddleWare = createAsyncThunk(
  GET_SUBSCRIPTIONS_ALL_PLANS,
  async () => {
    try {
      const { data } = await axios.get(getSubscriptionsAllPlans);
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
