import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";

type Props = {
  src?: string | any;
  className?: string;
  style?: React.CSSProperties;
};

const Image = ({ src, className, style }: Props) => {
  const [isLoad, setLoad] = useState(true);
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        alt="testo"
        className={className}
        style={style}
        onLoad={() => setLoad(false)}
        src={src}
      />
      {isLoad && (
        <CircularProgress style={{ position: "absolute" }} color="secondary" />
      )}
    </Box>
  );
};

export default Image;
