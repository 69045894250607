import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC5GaZeyWQxxBXKyAxIefF3OqlAbGXq2BE",
  authDomain: "testosteronology-ec364.firebaseapp.com",
  projectId: "testosteronology-ec364",
  storageBucket: "testosteronology-ec364.appspot.com",
  messagingSenderId: "715934343951",
  appId: "1:715934343951:web:f3c0c36f409455913ad47a",
  measurementId: "G-T6GRNCEVEX",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };
