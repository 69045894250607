// analyticsHelper.ts
import { trackCustomEvent } from "./facebookPixelHelper";
import { analytics } from "./firebaseConfig";
import { logEvent } from "firebase/analytics";
import { camelCaseString } from "./helpers";

interface EventParams {
  [key: string]: string | null | boolean | number;
}

// Store default parameters globally
let defaultEventParameters: EventParams = {};

/**
 * Function to set default event parameters.
 * These parameters will be included with every logged event.
 * @param props - The default parameters to set.
 */
export const setDefaultEventParameters = (props: EventParams): void => {
  defaultEventParameters = { ...defaultEventParameters, ...props };
  // console.log("Default event parameters set:", defaultEventParameters);
};

/**
 * Function to log an event with Firebase Analytics, merging default parameters.
 * @param eventName - The name of the event.
 * @param eventParams - Optional additional parameters to log with the event.
 */
export const analyticsEvent = ({
  eventName,
  parameters,
}: {
  eventName: string;
  parameters?: { [key: string]: any };
}): void => {
  // Merge default parameters with event-specific parameters
  const combinedParams = { ...defaultEventParameters, ...parameters };
  // Filter out parameters that are null
  const filteredParams = Object.fromEntries(
    Object.entries(combinedParams).filter(([_, v]) => v !== null)
  );
  trackCustomEvent(camelCaseString(eventName), filteredParams);
  logEvent(
    analytics,
    process.env.REACT_APP_DEBUG === "true" ? `dev_${eventName}` : eventName,
    filteredParams
  );
  // console.log(`Event logged: ${eventName}`, filteredParams);
};
