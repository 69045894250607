import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "./imagecarousel.module.scss"; // Import the CSS file for styles and animations
import classNames from "classnames/bind";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const cx = classNames.bind(styles);

type Props = {
  slides: { image: string; alt: string }[];
  autoSlide?: boolean;
  autoSlideInterval?: number;
  showArrows?: boolean;
  showPageNumber?: boolean;
};

const ImageCarousel = React.memo(
  ({
    slides,
    autoSlideInterval = 10000,
    autoSlide = true,
    showArrows = true,
    showPageNumber,
  }: Props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideInterval = useRef<NodeJS.Timeout | null>(null);

    // Function to go to the next slide
    const nextSlide = useCallback(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, [slides.length]);

    // Function to go to the previous slide
    const prevSlide = useCallback(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
      );
    }, [slides.length]);

    // Function to go to a specific slide
    const goToSlide = useCallback((index: number) => {
      setCurrentIndex(index);
    }, []);

    // Manage auto-slide start/stop with dependencies
    useEffect(() => {
      if (autoSlide) {
        slideInterval.current = setInterval(nextSlide, autoSlideInterval);
      }

      return () => {
        if (slideInterval.current) {
          clearInterval(slideInterval.current);
        }
      };
    }, [autoSlide, autoSlideInterval, nextSlide]);

    return (
      <div className={cx("carousel")} style={{ height: 400 }}>
        {showArrows && (
          <button
            className={cx("carousel__button", "carousel__button--left")}
            onClick={prevSlide}
          >
            <ChevronLeftIcon />
          </button>
        )}

        <div className={cx("carousel__slides")}>
          {slides.map((slide, index) => (
            <div
              className={cx("carousel__slide", {
                active: index === currentIndex,
              })}
              key={index}
            >
              <img src={slide.image} alt={slide.alt} />
            </div>
          ))}
        </div>

        {showArrows && (
          <button
            className={cx("carousel__button", "carousel__button--right")}
            onClick={nextSlide}
          >
            <ChevronRightIcon />
          </button>
        )}

        <div className={cx("carousel__indicators")}>
          {slides.map((_, index) => (
            <button
              key={index}
              className={cx("carousel__indicator", {
                active: index === currentIndex,
              })}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>

        {showPageNumber && (
          <div className={cx("carousel__paging")}>
            {currentIndex + 1} / {slides.length}
          </div>
        )}
      </div>
    );
  }
);

export default ImageCarousel;
